<template>
  <div class="mt-4">
    <div class="container mt-4 mb-3">
      <div
        class="row"
        style="justify-content: space-around; align-items: center"
      >
        <div>
          <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
            Painel BI
          </h1>
          <p>Veja os BI's do sistema</p>
        </div>
        <div>
          <img src="/assets/img/users.jpg" />
        </div>
      </div>

      <div class="row mt-5" style="justify-content: center">
        <div class="col-8">
          <b-form-input
            id="input-horizontal"
            placeholder="Pesquise bi's..."
            style="border: 1px solid #a1000c"
            class="input px-3"
          ></b-form-input>
        </div>
        <div>
          <b-button variant="danger mr-2" type="submit">Filtrar</b-button>
          <b-button variant="success" type="submit" :to="{ path: '/newBI/' }"
            >Novo BI</b-button
          >
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <div
        class="row"
        v-for="(bi, i) in bis"
        :key="i"
        style="justify-content: center; justify-content: space-evenly"
      >
        <div class="col-md-4">
          {{ bi.name }}
        </div>

        <div class="col-md-3"></div>

        <div class="col-md-1" style="text-align: right">
          <span
            class="material-icons-outlined"
            @click="getId(bi.id)"
            style="cursor: pointer"
          >
            edit
          </span>
          <span
            class="material-icons-outlined"
            style="cursor: pointer"
            @click="deleteBI(bi.id)"
          >
            delete
          </span>
        </div>
        <hr class="mt-2" style="background: #a1000c; width: 82%" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>

<script>
import UserService from "../../services/UserService/UserService";
import AreaService from "../../services/AreaService/AreaService";
import Swal from "sweetalert2";

export default {
  name: "UsersPage",
  data() {
    return {
      bis: [],
      areaService: new AreaService(),
      userService: null,
    };
  },
  mounted() {
    this.userService = new UserService();
    this.getAllBis();
  },
  methods: {
    async getAllBis() {
      this.bis = await new AreaService().allBis();
    },

    updateUsers(users) {
      this.users = users;
    },

    getId: async function (id) {
      if (id) {
        return this.$router.push({ path: `/newBI/${id}` });
      }
    },

    async deleteBI(id) {
      console.log("lala", id);
      Swal.fire({
        title: "Tem certeza que deseja deletar?",
        text: "Essa ação não poderá ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#BF0C0E",
        cancelButtonColor: "#A9A9A9",
        confirmButtonText: "Deletar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.areaService.deleteBI(id);
          Swal.fire("Deleted!", "BI deletado com sucesso", "success");
        }
      });
    },
  },
};
</script>
